<template>
  <div class="page-wrapper">
    <el-form ref="passForm" :rules="passRules" :model="passForm">
      <el-form-item prop="oldPassword" label="旧密码：">
        <el-input
          autocomplete="off"
          v-model="passForm.oldPassword"
          placeholder="请输入旧密码"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="新密码：">
        <el-input
          v-model="passForm.password"
          placeholder="请输入新密码"
          type="password"
          :show-password="true"
          autocomplete="new-password"
        >
        </el-input>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="changePwd('passForm')"
          >修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "password",
  data() {
    return {
      passForm: {
        oldPassword: "",
        password: "",
      },
      passRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    changePwd(formName) {
      let that = this;
      let userId = localStorage.getItem("userId");
      that.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .request("changePwd", {
              id: userId,
              oldPassword: this[formName].oldPassword,
              password: this[formName].password,
            })
            .then(() => {
              that.$message.success("密码修改成功，请重新登录！");
              localStorage.clear();
              that.$router.push({
                path: "/login",
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 300px;
  padding: 50px;
  // border: 1px solid #ccc;
  .el-form-item {
    margin-bottom: 30px;
  }
  .btn {
    /deep/.el-form-item__content {
      display: flex;
      justify-content: center;
      .el-button {
        width: 150px;
      }
    }
  }
}
</style>